import React, { useState, useEffect } from "react";
import { Box, Flex, useToast, Textarea, Tooltip } from "@chakra-ui/react";
import IconBox from "../../IconBox/IconBox";
import Card from "../../../components/Card/Card";
import axios from "axios";

import {
  GiGroundSprout,
  GiFertilizerBag,
  GiFarmer,
  GiWaterDivinerStick,
} from "react-icons/gi";
import { BsFillCloudSunFill, BsFillSunFill } from "react-icons/bs";
import { TbShovel } from "react-icons/tb";
import { useAutoAnimate } from "@formkit/auto-animate/react";

const materials = [
  // so para escolas de continuidade
  // {
  //   id: 1,
  //   name: "Hortícolas Outono",
  // description: `Conjunto de sementes e plântulas de hortícolas de Outono/Inverno (ex. couve, fava, ervilha, nabo, etc.)`,
  //   points: 1,
  //   pointsText: "1 ponto",
  //   icon: <BsFillCloudSunFill />,
  // },
  {
    id: 2,
    name: "Hortícolas Primavera",
    description: `Conjunto de sementes e plântulas de hortícolas de Primavera/Verão (ex. tomate, pimento, pepino, abóbora, feijão, cebola, etc.)`,
    points: 1,
    pointsText: "1 ponto",
    icon: <BsFillSunFill />,
  },
  {
    id: 3,
    name: "Aromáticas e Flores Comestíveis Primavera",
    description: `Conjunto de plantas perenes aromáticas em vaso para plantar nas bordaduras da horta (ex. lúcia-lima, tomilho, erva-príncipe, erva-cidreira, etc.) e um conjunto de flores comestíveis (ex. calêndula, cravo-túnico, amor-perfeito, camomila, etc.)`,
    points: 1,
    pointsText: "1 ponto",
    icon: <GiGroundSprout />,
  },
  {
    id: 4,
    name: "Fertilizantes",
    description: `Composto proveniente de estrume de cavalo e um adubo orgânico granulado rico em azoto`,
    points: 1,
    pointsText: "1 ponto",
    icon: <GiFertilizerBag />,
  },
  {
    id: 5,
    name: "Ferramentas",
    description: `Conjunto de ferramentas apropriado às necessidades da escola/instituição`,
    points: 1,
    pointsText: "1 ponto",
    icon: <TbShovel />,
  },
  // {
  //   id: 6,
  //   name: "Apoio à 1ª Cava - Continuidade",
  //   points: 2,
  //   pointsText: "2 pontos",
  //   icon: <GiFarmer />,
  // },
  {
    id: 7,
    name: "Apoio à Cava",
    description: `O apoio à cava corresponde a uma ajuda no sentido de mobilizar o solo de uma horta que já existe com cerca de 10 m2. A conversão de um terreno virgem em solo arável para cultivo não está contemplada neste recurso. No entanto, damos apoio técnico para a instalação de novas hortas.`,
    points: 4,
    pointsText: "4 pontos",
    icon: <GiFarmer />,
  },
  {
    id: 8,
    name: "Sistema de Rega Gota-a-gota",
    description: `Conjunto de peças de rega, fornecidas juntamente com um guião que orienta a montagem por parte da escola`,
    points: 3,
    pointsText: "3 pontos",
    icon: <GiWaterDivinerStick />,
  },
];

const materialsAutumn = [
  {
    id: 9,
    name: "Apoio à Cava Outono (10 m2)",
    description: `Preparação de 10m2 de solo para a plantação de hortícolas`,
    points: 4,
    pointsText: "4 pontos",
    icon: <GiFarmer />,
  },
  {
    id: 10,
    name: "Fertilizantes Outono",
    description: `Composto proveniente de estrume de cavalo e um adubo orgânico granulado rico em azoto`,
    points: 1,
    pointsText: "1 pontos",
    icon: <GiFertilizerBag />,
  },
  {
    id: 11,
    name: "Hortícolas Outono",
    description: `Conjunto de sementes e plântulas de hortícolas de outono/inverno (ex. couve, ervilha, fava, alface, nabo, nabiça, etc.)`,
    points: 1,
    pointsText: "1 ponto",
    icon: <BsFillSunFill />,
  },
];

const Material = ({ setDetails, details }) => {
  const toast = useToast();
  const [animationParent] = useAutoAnimate();
  const { observations } = details;
  const [schoolPoints, setSchoolPoints] = useState(4);

  const handleChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    fetchSchoolPoints();
    return () => {};
  }, []);

  /**
   * fetch points for the school
   */
  const fetchSchoolPoints = async () => {
    try {
      const bookingData = await axios.get(
        `/schools/getPointsUsedInAutumn/${details?.school?.school}`
      );
      if (!bookingData.materials && !bookingData?.data?.materials) {
        throw new Error("no data");
      }

      if (bookingData?.data?.materials?.length > 0) {
        workOutPoints(
          bookingData?.data?.schoolPoints || 4,
          bookingData?.data?.materials
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  //letsworkout the point from the materials used in autumn and update
  const workOutPoints = (points, materials) => {
    //lets loop throught the material list for autumn and remove from the total school points
    const pointDifference = points - schoolPoints;
    let totalPoints = schoolPoints + pointDifference;

    materialsAutumn.map((item) => {
      if (materials.includes(item.name)) {
        totalPoints -= item.points;
      }
      return item;
    });
    setSchoolPoints(totalPoints);
  };

  // togle details materia array true false
  const toggleChange = (name) => {
    if (details.material.includes(name)) {
      setDetails({
        ...details,
        material: details.material.filter((item) => item !== name),
      });
    } else {
      //lets check if we have less than 4 points
      let totalPoints =
        materials.find((item) => item.name === name)?.points || 0;

      details.material.map((val) => {
        totalPoints += materials.find((item) => item.name === val)?.points || 0;
        return val;
      });

      if (totalPoints <= schoolPoints) {
        setDetails({
          ...details,
          material: [...details.material, name],
        });
      } else {
        toast({
          title: `Já não tem pontos`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Box width={"100%"} ref={animationParent}>
      <Box padding={"0 2em"}>
        <strong>Escolha os recursos (total de {schoolPoints})</strong>
      </Box>
      <Flex
        flexWrap={"wrap"}
        display={"inline-flex"}
        gap={"1em"}
        w={"100%"}
        m="1em 0"
      >
        {materials.map((m, i) => {
          return (
            <Tooltip hasArrow label={m.description} aria-label="A tooltip">
              <Box
                key={i}
                width={{ base: "100%", md: "48%" }}
                className={`buttonCard ${
                  details.material.includes(m.name) && "active"
                }`}
                onClick={() => toggleChange(m.name)}
              >
                <Box fontWeight={"bold"}>
                  {m.name}

                  <Box fontSize={".8em"} opacity={"0.6"}>
                    {m.pointsText}
                  </Box>
                </Box>
                <Box>
                  <IconBox icon={m.icon} />
                </Box>
              </Box>
            </Tooltip>
          );
        })}
      </Flex>
      <Card margin={"0 0 1em 0"}>
        <Box width={"100%"}>
          <label>Cometários sobre o material necessário</label>
          <Textarea
            rows={5}
            width={"100%"}
            name={"observations"}
            value={observations}
            onChange={handleChange}
            placeholder="Exemplo: as ferramentas que precisamos são para cavar…"
          />
        </Box>
      </Card>
    </Box>
  );
};

export default Material;
