import React from "react";
import Card from "../../../components/Card/Card";
import {
  Input,
  Textarea,
  Box,
  HStack,
  VStack,
  Flex,
  useToast,
} from "@chakra-ui/react";
import IconBox from "../../IconBox/IconBox";
import { GiTap, GiWaterDivinerStick } from "react-icons/gi";
import { BsFillInboxFill, BsFillSunFill } from "react-icons/bs";
import { TbShovel } from "react-icons/tb";
import { useAutoAnimate } from "@formkit/auto-animate/react";

const Horta = ({ setDetails, details }) => {
  const toast = useToast();
  const [animationParent] = useAutoAnimate();
  const { area, slots, tutors } = details.horta;

  const hortasDetailsList = [
    {
      id: 1,
      name: "Exposição solar direta	",
      pointsText: "1 ponto",
      icon: <BsFillSunFill />,
    },
    {
      id: 2,
      name: "Ponto de água",
      pointsText: "1 ponto",
      icon: <GiTap />,
    },
    {
      id: 3,
      name: "Sistema de rega",
      pointsText: "1 ponto",
      icon: <GiWaterDivinerStick />,
    },
    {
      id: 4,
      name: "Ferramentas",
      pointsText: "1 ponto",
      icon: <TbShovel />,
    },
    {
      id: 5,
      name: "Compostor",
      pointsText: "1 ponto",
      icon: <BsFillInboxFill />,
    },
  ];

  const handleChange = (e) => {
    setDetails({
      ...details,
      horta: {
        ...details.horta,
        [e.target.name]: e.target.value,
      },
    });
  };

  // togle details materia array true false
  const toggleChange = (name) => {
    if (details.horta.list.includes(name)) {
      setDetails({
        ...details,
        horta: {
          ...details.horta,
          list: details.horta.list.filter((item) => item !== name),
        },
      });
    } else {
      setDetails({
        ...details,
        horta: { ...details.horta, list: [...details.horta.list, name] },
      });
    }
  };

  return (
    <Box width={"100%"} ref={animationParent}>
      <Card margin={"0 0 1em 0"}>
        <VStack>
          <HStack flexDir={["column", "row"]}>
            <Box width={"100%"}>
              <label>
                Área da Horta (m2) <span>*</span>
              </label>
              <Input
                value={area}
                type="number"
                name={"area"}
                onChange={handleChange}
                placeholder="10"
              />
            </Box>
            <Box width={"100%"}>
              <label>
                Nº de Alunos Envolvidos <span>*</span>
              </label>
              <Input
                type="number"
                value={slots}
                name={"slots"}
                onChange={handleChange}
                placeholder="50"
              />
            </Box>
          </HStack>
          <Box width={"100%"}>
            <label>
              Nº de Docentes Envolvidos <span>*</span>
            </label>
            <Input
              type="number"
              value={tutors}
              name={"tutors"}
              onChange={handleChange}
              placeholder="4"
            />
          </Box>
        </VStack>
      </Card>
      <Box padding={"0 2em"}>
        <strong>Selecione os recursos que a horta possui</strong>
      </Box>

      <Flex
        flexWrap={"wrap"}
        display={"inline-flex"}
        gap={"1em"}
        w={"100%"}
        m="1em 0"
      >
        {hortasDetailsList.map((m, i) => {
          return (
            <Box
              key={i}
              width={{ base: "100%", md: "48%" }}
              className={`buttonCard ${
                details.horta.list.includes(m.name) && "active"
              }`}
              onClick={() => toggleChange(m.name)}
            >
              <Box fontWeight={"bold"}>
                {m.name}
                {/* 
                <Box fontSize={".8em"} opacity={"0.6"}>
                  {m.pointsText}
                </Box> */}
              </Box>
              <Box>
                <IconBox icon={m.icon} />
              </Box>
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
};

export default Horta;
