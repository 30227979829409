import React from "react";
import "./styles.scss";
import { Box } from "@chakra-ui/react";

const Card = (props) => {
  return (
    <Box
      className="cardWrap"
      background={props.background}
      color={props.color}
      padding={props.padding}
      margin={props.margin}
    >
      {props.children}
    </Box>
  );
};

export default Card;
