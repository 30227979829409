import React, { useState, useEffect } from "react";
import { Box, useToast } from "@chakra-ui/react";
import axios from "axios";
import Card from "../Card/Card";

const Thankyou = ({ token }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    const confirmReservation = async () => {
      if (!loading) {
        setLoading(true);
        try {
          const setData = await axios.get(`/schools/confirm/${token}`);
          toast({
            title: "inscrição confirmada com sucesso",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setSaved(true);
        } catch (error) {
          toast({
            title: "Aconteceu um erro a confirmar a sua inscrição",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }

        setLoading(false);
      }
    };
    confirmReservation();
  }, [token]);

  return (
    <Box width={"100%"} padding={"0 2em"} mt={"10vh"}>
      <Box
        lineHeight={"1"}
        fontWeight={"bold"}
        color={"#599331"}
        fontSize={"2.5em"}
        mb={".5em"}
      >
        {saved
          ? `Muito obrigado, a sua inscrição foi confirmada...`
          : `Aguarde a confirmação da sua inscrição...`}
      </Box>
      <Card>
        {!saved
          ? `A salvar...`
          : `A nossa equipa irá confirmar a sua inscrição e entrar em contacto caso
        necessário.`}
      </Card>
    </Box>
  );
};

export default Thankyou;
