import React from "react";
import "./styles.scss";

import { Box } from "@chakra-ui/react";

const SchoolTitle = ({ content, school }) => {
  const { title, description } = content;
  return (
    <Box className="activity-overview">
      <h2>{title}</h2>
      <p className="local">{school?.school || ""}</p>
      <div
        className="details"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Box>
  );
};

export default SchoolTitle;
