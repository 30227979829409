import React from "react";
import ImageSide from "../ImageSide/ImageSide";
import "./styles.scss";
import { Flex, Box, VStack } from "@chakra-ui/react";

const ContentSide = (props) => {
  return (
    <Flex
      height={{ base: "auto", md: "auto" }}
      minHeight={{ base: "auto", md: "100vh" }}
      direction={{ base: "column", md: "row" }}
      overflow={{ base: "visible", md: "auto" }}
    >
      <Flex
        order={{ base: "1", md: "0" }}
        w={{ base: "100%", md: "60%" }}
        justifyContent={"center"}
        padding={{ base: "60px 0 0 0", md: "60px 0 0 0" }}
        // overflow={{ base: "visible", md: "auto" }}
        className={"content-side-wrap"}
      >
        <VStack
          spacing={10}
          justify="center"
          // alignItems={"flex-start"}
          height="100%"
          width={"80%"}
          mb={"2em"}
          maxWidth={"600px"}
        >
          {props.children}
        </VStack>
      </Flex>
      <ImageSide src={props.image} />
    </Flex>
  );
};

export default ContentSide;
