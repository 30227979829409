import React from "react";
import Card from "../../Card/Card";
import { Textarea, Box, useToast } from "@chakra-ui/react";

import { useAutoAnimate } from "@formkit/auto-animate/react";

const Curricular = ({ setDetails, details }) => {
  const toast = useToast();
  const [animationParent] = useAutoAnimate();
  const { curricular } = details;

  const handleChange = (e) => {
    setDetails({
      ...details,
      curricular: e.target.value,
    });
  };
  return (
    <Box width={"100%"} ref={animationParent}>
      <Card margin={"0 0 1em 0"}>
        <Box width={"100%"}>
          <label>Atividades Curricular</label>
          <Textarea
            rows={5}
            width={"100%"}
            name={"curricular"}
            value={curricular}
            onChange={handleChange}
            placeholder="uma breve descrição sobre Atividades de âmbito curricular relacionadas com a dinamização da horta"
          />
        </Box>
      </Card>
    </Box>
  );
};

export default Curricular;
