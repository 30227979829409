import React, { useState } from "react";
import {
  Input,
  Box,
  HStack,
  VStack,
  Divider,
  Switch,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";

import Card from "../../Card/Card";
import "./styles.scss";
import dayjs from "dayjs";

const Summary = ({ setDetails, details }) => {
  const {
    students,
    material,
    responsible,
    volunteer,
    history,
    curricular,
    book,
    school: { school, agrupamento },
    horta: { area, slots, tutors },
    owner: { admin, person },
  } = details || {};

  const { list } = details.activities || {};
  const [toggleModal, setToggleModal] = useState(false);

  const handleChange = (e) => {
    const elementID = e.target?.dataset?.elemid || "admin";

    setDetails({
      ...details,
      owner: {
        ...details.owner,
        [elementID]: {
          ...details.owner[elementID],
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  return (
    <>
      <Card margin={"0 0 2em 0"}>
        <Box width={"100%"} className={"summary"} mt={0}>
          <VStack>
            <Box className="summary-title" mt={"0 !important"}>
              Responsável pelo Projeto
            </Box>
            <HStack width={"100%"} flexDir={["column", "row"]}>
              <Box width={"100%"}>
                <label>
                  Nome
                  <span>*</span>
                </label>
                <Input
                  data-elemid={"person"}
                  value={person.name}
                  name={"name"}
                  onChange={handleChange}
                  placeholder="Primeiro e Último Nome"
                />
              </Box>
              <Box width={"100%"}>
                <label>
                  Email
                  <span>*</span>
                </label>
                <Input
                  data-elemid={"person"}
                  value={person.email}
                  name={"email"}
                  onChange={handleChange}
                  placeholder="repEmail@email.pt"
                />
              </Box>
            </HStack>{" "}
            <Box className="summary-title">
              Diretor(a) da Escola e/ou do Agrupamento de Escolas
            </Box>
            <HStack width={"100%"} flexDir={["column", "row"]}>
              <Box width={"100%"}>
                <label>
                  Nome
                  <span>*</span>
                </label>
                <Input
                  data-elemid={"admin"}
                  value={admin.name}
                  name={"name"}
                  onChange={handleChange}
                  placeholder="Primeiro e Último Nome"
                />
              </Box>{" "}
              <Box width={"100%"}>
                <label>
                  Email
                  <span>*</span>
                </label>
                <Input
                  data-elemid={"admin"}
                  value={admin.email}
                  name={"email"}
                  onChange={handleChange}
                  placeholder="adminemail@email.pt"
                />
              </Box>
            </HStack>
            <Box width={"100%"}>
              <FormControl mt={"1em"} display="flex" alignItems="center">
                <Switch
                  colorScheme="teal"
                  id="email-alerts"
                  mr={"1em"}
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      owner: {
                        ...details.owner,
                        privacy: e.target.checked,
                      },
                    })
                  }
                />
                <FormLabel
                  htmlFor="email-alerts"
                  color={"#000"}
                  mb="0"
                  fontSize={"1rem"}
                  cursor={"pointer"}
                  onClick={() => setToggleModal(true)}
                >
                  Aceito a Política de privacidade e tratamento de dados
                  pessoais <span>*</span>
                </FormLabel>
              </FormControl>
            </Box>
          </VStack>
        </Box>
      </Card>
      <Card>
        <Box width={"100%"} className={"summary"}>
          <VStack>
            <Box className="summary-title">Escola</Box>
            <HStack width={"100%"}>
              {school && (
                <Box width={"100%"}>
                  <label>Escola</label>
                  <Box className={"summary-value"}>{school}</Box>
                </Box>
              )}
            </HStack>

            <HStack width={"100%"}>
              <Box width={"100%"}>
                <label>Agrupamento</label>
                <Box className={"summary-value"}>{agrupamento}</Box>
              </Box>
            </HStack>
            <Divider orientation="horizontal" padding={"1em 0"} />
            <Box className="summary-title">Responsáveis</Box>

            {responsible &&
              responsible.map((resp, i) => {
                return (
                  <Box key={i} width={"100%"}>
                    <HStack width={"100%"}>
                      <Box width={"100%"}>
                        <label>Nome</label>
                        <Box className={"summary-value"}>{resp.name}</Box>
                      </Box>
                      <Box width={"100%"}>
                        <label>Sobrenome</label>
                        <Box className={"summary-value"}>{resp.surname}</Box>
                      </Box>
                      <Box width={"100%"}>
                        <label>NIF</label>
                        <Box className={"summary-value"}>{resp.vat}</Box>
                      </Box>
                    </HStack>

                    <HStack width={"100%"}>
                      <Box width={"100%"}>
                        <label>Telefone</label>
                        <Box className={"summary-value"}>{resp.phone}</Box>
                      </Box>
                      <Box width={"100%"}>
                        <label>Email</label>
                        <Box className={"summary-value"}>{resp.email}</Box>
                      </Box>
                    </HStack>
                    <Divider orientation="horizontal" padding={"1em 0"} />
                  </Box>
                );
              })}
            <Box className="summary-title">Formandos</Box>

            {students &&
              students.map((resp, i) => {
                return (
                  <Box key={i} width={"100%"}>
                    <HStack width={"100%"}>
                      <Box width={"100%"}>
                        <label>Nome</label>
                        <Box className={"summary-value"}>{resp.name}</Box>
                      </Box>
                      <Box width={"100%"}>
                        <label>Sobrenome</label>
                        <Box className={"summary-value"}>{resp.surname}</Box>
                      </Box>
                    </HStack>

                    <HStack width={"100%"}>
                      <Box width={"100%"}>
                        <label>Telefone</label>
                        <Box className={"summary-value"}>{resp.phone}</Box>
                      </Box>
                      <Box width={"100%"}>
                        <label>Email</label>
                        <Box className={"summary-value"}>{resp.email}</Box>
                      </Box>
                    </HStack>
                    <Divider orientation="horizontal" padding={"1em 0"} />
                  </Box>
                );
              })}
            <Box className="summary-title">Material Escolhido</Box>
            <HStack width={"100%"} flexDir={["column", "row"]}>
              {material &&
                material.map((m, i) => {
                  return (
                    <Box width={"100%"} key={i}>
                      <Box className={"summary-value"}>{m}</Box>
                    </Box>
                  );
                })}
            </HStack>
            <Divider orientation="horizontal" padding={"1em 0"} />
            <Box className="summary-title">Informação sobre a Horta</Box>
            <HStack width={"100%"} flexDir={["column", "row"]}>
              <Box width={"100%"}>
                <label>Area </label>
                <Box className={"summary-value"}>{area}</Box>
              </Box>
              <Box width={"100%"}>
                <label>Alunos</label>
                <Box className={"summary-value"}>{slots}</Box>
              </Box>
              <Box width={"100%"}>
                <label>Professores</label>
                <Box className={"summary-value"}>{tutors}</Box>
              </Box>

              <Box width={"100%"}>
                <label>Voluntário</label>
                <Box className={"summary-value"}>
                  {volunteer && volunteer === "no" && "Não"}
                  {volunteer && volunteer === "yes" && "Sim"}
                  {volunteer && volunteer === "dontknow" && "Não sei"}
                </Box>
              </Box>
            </HStack>
            <Box width={"100%"}>
              <label>Equipamentos disponíveis</label>
            </Box>
            <HStack width={"100%"} flexDir={["column", "row"]}>
              {details.horta &&
                details.horta.list &&
                details.horta.list.map((m, i) => {
                  return (
                    <Box width={"100%"} key={i}>
                      <Box className={"summary-value"}>{m}</Box>
                    </Box>
                  );
                })}
            </HStack>
            <Box width={"100%"}>
              <label>História da Horta</label>
            </Box>
            <Box className={"summary-value"} w={"100%"}>
              {history}
            </Box>
            <Box width={"100%"}>
              <label>Atividades Curricular</label>
            </Box>
            <Box className={"summary-value"} w={"100%"}>
              {curricular}
            </Box>
          </VStack>
        </Box>
      </Card>

      <Modal
        // scrollBehavior={"inside"}
        isOpen={toggleModal}
        size={"xl"}
        onClose={() => setToggleModal(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Política de privacidade e tratamento de dados pessoais
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <p>
                <span>
                  Esta Política de Privacidade e de Tratamento de Dados Pessoas
                  (“Política”) descreve o tratamento de dados pessoais no âmbito
                  da reserva e participação nas atividades e atribuição de
                  parcelas no âmbito do programa Terras de Cascais da Cascais
                  Ambiente.
                </span>
              </p>
              <p>
                <span>
                  Ao remeter os dados pessoais aquando da sua reserva, o
                  requerente declara aceitar integralmente o disposto nesta
                  Política de Privacidade e de Tratamento de Dados Pessoas.
                  Assim sendo, recomendamos que a leia atentamente.
                </span>
              </p>
              <p>
                <span>
                  <strong>1. IDENTIFICAÇÃO DO PROJETO</strong>
                </span>
              </p>
              <p>
                <span>
                  O programa Terras de Cascais pretende dar nova vida a espaços
                  naturais e promover a agricultura urbana biológica. A
                  agricultura urbana começou em Cascais com a criação das
                  primeiras Hortas Comunitárias. Das hortas passámos para os
                  pomares, olivais e para as vinhas. O projeto cresceu para
                  apoiar e incentivar a produção e o consumo de produtos frescos
                  locais, biológicos e sazonais.&nbsp;
                </span>
              </p>
              <p>
                <span>
                  <strong>2. RESPONSÁVEL PELO TRATAMENTO</strong>
                </span>
              </p>
              <p>
                <span>
                  <strong>2.1.</strong> O responsável pelo tratamento de dados
                  pessoais recolhidos através dos formulários do programa é o
                  Município de Cascais, pessoa coletiva de direito público com o
                  NIPC 505187531 e sede na Praça 5 de Outubro, 2754-501 Cascais
                  (“Responsável”).
                </span>
              </p>
              <p>
                <span>
                  <strong>2.2.</strong> Sem prejuízo do aqui disposto, sempre
                  que a informação enviada envolva o acesso, utilização e/ou
                  consulta de informação ou serviços da responsabilidade de
                  empresas, organismos, associações ou outras pessoas coletivas
                  integradas no universo da Câmara Municipal de Cascais, o
                  Responsável procede à comunicação de dados a tais empresas,
                  organismos, associações ou outras pessoas coletivas,
                  procedendo as mesmas ao tratamento de dados pessoais na
                  qualidade de corresponsáveis (“Corresponsáveis”).
                </span>
              </p>
              <p>
                <span>
                  <strong>2.3.</strong> O disposto na presente Política
                  relativamente ao Responsável aplica-se, assim, a todos os
                  tratamentos de dados pessoais efetuados no âmbito desta
                  aplicação pelas Corresponsáveis. As Corresponsáveis, e as
                  finalidades da comunicação e tratamento de dados pessoais aqui
                  previstos, são identificadas no ponto 6 desta Política.
                </span>
              </p>
              <p>
                <br />
              </p>
              <p>
                <span>
                  <strong>3. CONSENTIMENTO</strong>
                </span>
              </p>
              <p>
                <span>
                  <strong>3.1.</strong> Ao aceitar a presente Política, o
                  utilizador autoriza expressamente o Responsável e, quando
                  aplicável, Corresponsáveis, a recolher, registar, conservar,
                  integrar, comunicar e a tratar, informaticamente ou não, para
                  as finalidade e termos desta Política, os respetivos dados
                  pessoais, incluindo dados de gestão, solicitação e/ou consulta
                  de serviços através de meios digitais, físicos ou de
                  comunicação.
                </span>
              </p>
              <p>
                <span>
                  <strong>3.2</strong>.&nbsp;
                </span>
                <span>
                  A proteção, transparência e privacidade dos seus dados e
                  informações pessoais não é só obrigatória (como consta no
                  RGPD) mas é absolutamente fundamental para exercermos a nossa
                  atividade com a excelência e o rigor.&nbsp;
                </span>
                <span>
                  Ao recusar aceitar a presente Política, o requerente não
                  poderá ser candidato a nenhum projeto ou participação nas
                  terras de Cascais, efetuar qualquer formação ou participar em
                  qualquer projeto neste âmbito.
                </span>
              </p>
              <p>
                <br />
              </p>
              <p>
                <span>
                  <strong>
                    4. DESCRIÇÃO DO TRATAMENTO E TIPOS DE DADOS PESSOAIS
                    TRATADOS
                  </strong>
                </span>
              </p>
              <p>
                <span>
                  <strong>4.1.</strong> O tratamento de dados pessoais inclui
                  (a) dados transmitidos pelo utilizador ao Município de
                  Cascais, incluindo entidades do universo municipal, ou por
                  este recolhidos e/ou conservados no âmbito das relações
                  estabelecidas entre o utilizador, na qualidade de utente de
                  bens ou serviços municipais e/ou morador na área de jurisdição
                  do município, e o Município e quaisquer das entidades do
                  respetivo universo, quanto a quaisquer serviços, prestações ou
                  bens, solicitados; e (b) os dados transmitidos pelo utilizador
                  no âmbito de alteração, ou complementação, de dados já
                  submetidos, incluindo, mas não limitando através de
                  formulários, correio eletrónico, contactos telefónicos ou
                  presenciais.
                </span>
              </p>
              <p>
                <span>
                  <strong>4.2.</strong> Os dados pessoais tratados incluem dados
                  relacionados como:
                </span>
              </p>
              <p>
                <span>
                  <strong>(i)</strong> identificação, incluindo nome, data de
                  nascimento e NIF, essenciais para a identificação unívoca do
                  utilizador, sendo requisitos essenciais para a correta
                  operação da base de dados e plataformas informáticas que estão
                  envolvidas no tratamento das reservas das Atividades de
                  Natureza;
                </span>
              </p>
              <p>
                <span>
                  <strong>(ii)</strong> contacto, incluindo o endereço de
                  correio eletrónico e o número de contacto direto, essenciais
                  para as comunicações posteriores à submissão dos dados;
                </span>
              </p>
              <p>
                <span>
                  <strong>(iii)</strong> faturação, incluindo NIF, a morada e
                  código postal, essenciais para a emissão e envio de faturas;
                </span>
              </p>
              <p>
                <span>
                  <strong>(iv)</strong> dados opcionais como imagem e som.
                </span>
              </p>
              <p>
                <span>
                  <strong>5. FINALIDADES DO TRATAMENTO</strong>
                </span>
              </p>
              <p>
                <span>
                  <strong>5.1.</strong> O tratamento de dados pessoais
                  recolhidos através do programa Terras de Cascais tem, por
                  finalidade, a prestação dos serviços requisitadas através do
                  processamento da reserva bem como a sua faturação e a promoção
                  futura da imagem das Terras de Cascais.
                </span>
              </p>
              <p>
                <br />
              </p>
              <p>
                <span>
                  <strong>5.2.</strong> Para além do tratamento efetuado para as
                  finalidades aqui descritas, os dados recolhidos podem ser
                  utilizados para efeitos de contacto e prestação de informação
                  aos integrantes do projeto, bem como realização de inquéritos,
                  sondagens ou questionários para aferir do grau de satisfação
                  com os serviços prestados.
                </span>
              </p>
              <p>
                <span>
                  <strong>5.3.</strong> Podem igualmente ser recolhidos dados
                  anonimizados e para efeitos meramente estatísticos sobre a
                  experiência do projeto, na medida do necessário ou útil para a
                  gestão, desenvolvimento e melhoria do mesmo.
                </span>
              </p>
              <p>
                <span>
                  <strong>6. COMUNICAÇÕES DE DADOS</strong>
                </span>
              </p>
              <p>
                <span>
                  <strong>6.1.</strong> O Responsável comunica e recebe dados,
                  para efeitos das finalidades previstas nesta Politica, de
                  empresas municipais e concessionárias de serviços municipais
                  no âmbito das respetivas competências, bem como de
                  instituições de ação social, de associações recreativas,
                  desportivas e culturais e voluntariado e estabelecimentos de
                  ensino, incluindo, designadamente: EMAC – Empresa Municipal de
                  Ambiente de Cascais, E.M., S.A., com sede no Complexo
                  Municipal Multi-serviços da Adroana, Estrada de Manique, 1830,
                  Alcoitão, Alcabideche; Fundação São Francisco de Assis, e
                  estabelecimentos escolares e desportivos no âmbito municipal.
                </span>
              </p>
              <p>
                <span>
                  <strong>6.2.</strong> São ainda comunicados dados, no contexto
                  das relações estabelecidas com o utilizador e utente do
                  Município de Cascais, quando legalmente obrigatório ou
                  necessário para efeitos do cumprimento de obrigações legais e
                  de informação ou execução dos serviços solicitados pelo
                  utilizador, aos serviços de finanças, INE, juntas de
                  freguesia, conservatórias, administração central, proteção
                  civil e polícia municipal.
                </span>
              </p>
              <p>
                <span>
                  <strong>7. DIREITOS DO UTILIZADOR</strong>
                </span>
              </p>
              <p>
                <span>
                  <strong>7.1.</strong> O utilizador tem os direitos de acesso,
                  retificação, portabilidade e eliminação de dados pessoais, bem
                  como o direito a opor-se ao tratamento dos mesmos, e de
                  conhecer as entidades a quem os seus dados pessoais são
                  comunicados, consoante o caso.
                </span>
              </p>
              <p>
                <span>
                  <strong>7.2.</strong> Sempre que nisso tenha consentido, o
                  utilizador poderá igualmente opor-se à utilização dos seus
                  dados pessoais pelo Responsável e/ou por outras entidades a
                  quem os dados tenham sido comunicados para efeitos do disposto
                  no ponto 5.3. desta Política.
                </span>
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>
                <span>
                  <strong>7.3.</strong> O exercício dos direitos mencionados
                  poderá ser realizado mediante comunicação escrita ou por
                  correio eletrónico para os seguintes contactos: Chefe de
                  Divisão de Atendimento (ou Encarregado de Proteção de Dados)
                  da Câmara Municipal de Cascais, Edifício Cascais Center Rua
                  Manuel Joaquim Avelar, n.º 118 piso -1 2750-281 Cascais,
                  atendimento.municipal@cm-cascais.pt, 800 203 186.
                </span>
              </p>
              <p>
                <span>
                  <strong>7.4.</strong> O utilizador tem, ainda, o direito de
                  apresentar reclamação a uma autoridade de controlo,
                  nomeadamente à Comissão Nacional de Proteção de Dados (“CNPD”)
                  (https://www.cnpd.pt/).
                </span>
              </p>
              <p>
                <span>
                  <strong>8. MEDIDAS DE SEGURANÇA</strong>
                </span>
              </p>
              <p>
                <span>
                  <strong>8.1</strong> O Responsável adota as medidas técnicas e
                  organizativas necessárias para assegurar a segurança e
                  confidencialidade dos seus dados pessoais, designadamente
                  medidas técnicas e organizativas razoáveis para impedir a
                  consulta, remoção, modificação ou divulgação não autorizadas
                  ou involuntárias, bem como a perda de dados pessoais.
                </span>
              </p>
              <p>
                <span>
                  <strong>8.2.</strong> O utilizador é advertido, no entanto,
                  para a possibilidade de os seus dados serem vistos e
                  utilizados por terceiros, designadamente quando utiliza
                  equipamentos e redes não seguras (incluindo redes públicas e
                  redes aberta). O utilizador deve assim adotar as medidas de
                  segurança adequadas, evitando equipamentos e redes não seguros
                  e fazendo uso das ferramentas e configurações de segurança e
                  privacidade que lhe sejam disponibilizadas tanto no
                  dispositivo ou navegador (browser) utilizados, como através de
                  software específico que permita reduzir o risco de interceção
                  de comunicações e/ou de acesso ao seu dispositivo.
                </span>
              </p>
              <p>
                <span>
                  <strong>8.3.</strong> O Responsável não assume, em caso algum,
                  qualquer responsabilidade pela interceção, acesso, modificação
                  ou perda de dados pessoais em resultado da utilização de
                  equipamentos, redes, software ou outros meios ou comunicações
                  não seguras.
                </span>
              </p>
              <p>
                <span>
                  <strong>9. SUBCONTRATAÇÃO</strong>
                </span>
              </p>
              <p>
                <span>
                  <strong>9.1.</strong> O Responsável pode recorrer a
                  subcontratados no âmbito da ação, transporte e outros
                  serviços.
                </span>
              </p>
              <p>
                <span>
                  <strong>9.2.</strong> Os subcontratados podem ter acesso aos
                  seus dados pessoais, e atuam unicamente de acordo com as
                  instruções e orientações do Responsável, adotando as medidas
                  de segurança técnicas e organizativas adequadas e exigidas
                  para assegurar a confidencialidade e segurança dos seus dados
                  pessoais.
                </span>
              </p>
              <p>
                <span>
                  <strong>10. CONSERVAÇÃO DE DADOS</strong>
                </span>
              </p>
              <p>
                <span>
                  <strong>10.1.</strong> Sem prejuízo da faculdade de
                  atualização, correção ou eliminação de dados pelo utilizador,
                  os dados pessoais tratados no âmbito desta Política serão
                  conservados enquanto estiver pendente ou em execução qualquer
                  solicitação, ação ou serviço requerido, e por um período de 5
                  (cinco) anos após a última submissão.
                </span>
              </p>
              <p>
                <span>
                  <strong>10.2</strong> O exercício do direito ao esquecimento
                  no âmbito deste projeto não implica a eliminação de dados e
                  informações relativas ao utilizador enquanto utente de outros
                  serviços, prestações ou bens, ou no âmbito da sua relação com
                  o Município de Cascais e quaisquer entidades integradas no
                  universo municipal, os quais serão conservados pelo período
                  mínimo de 10 (dez) anos, salvo obrigação legal de conservar
                  tais dados por período maior.
                </span>
              </p>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Summary;
