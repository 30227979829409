import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Radio,
  RadioGroup,
  Input,
  Textarea,
  Select,
  Box,
  Stack,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import { schools as schoollData } from "../../../config/schools";

const School = ({ setDetails, details }) => {
  const [schools, setSchools] = useState([]);
  const [group, setGroup] = useState([]);
  const [years, setYears] = useState([]);
  const { year, school, yearDetail, type, className, slots, agrupamento } =
    details.school;
  const [animationParent] = useAutoAnimate();

  /**
   * Get Schools
   */
  useEffect(() => {
    async function fetchData() {
      setSchools(schoollData.schools);
      setGroup(schoollData.agrupamento);
    }
    fetchData();
  }, [schoollData]);

  const renderPublic = () => {
    return (
      <>
        <Box width={"100%"}>
          <label>
            Agrupamento <span>*</span>
          </label>
          <Select
            value={agrupamento}
            name={"agrupamento"}
            onChange={handleChange}
            placeholder={"Pesquisar Agrupamento"}
          >
            {group.map((name, index) => (
              <option key={index} value={name}>
                {name}
              </option>
            ))}
          </Select>
        </Box>
        {agrupamento && (
          <Box width={"100%"}>
            <label>
              Nome da Escola <span>*</span>
            </label>

            <Select
              value={school}
              name={"school"}
              onChange={handleChange}
              list="school"
              placeholder={"Pesquisar escola"}
            >
              {schools &&
                schools
                  .filter((s) => {
                    return s.agrupamento === agrupamento;
                  })
                  .map((name, index) => (
                    <option key={index} value={name.escola}>
                      {name.escola}
                    </option>
                  ))}
            </Select>
          </Box>
        )}
      </>
    );
  };

  const handleChange = (e) => {
    setDetails({
      ...details,
      school: {
        ...details.school,
        [e.target.name]: e.target.value,
      },
    });
  };

  const renderPrivate = () => {
    return (
      <>
        <Box width={"100%"}>
          <label>
            Nome da Escola <span>*</span>
          </label>

          <Input
            value={school}
            name={"school"}
            onChange={handleChange}
            placeholder={"Nome da Escola"}
          />
        </Box>
      </>
    );
  };

  return (
    <Box width={"100%"} ref={animationParent}>
      <VStack>
        <Box textAlign={"left"} width={"100%"}>
          <RadioGroup
            name={"type"}
            onChange={(e) =>
              handleChange({ target: { name: "type", value: e } })
            }
            value={type}
          >
            <Stack spacing={5} direction="row">
              <Radio value="public">Escola Publica</Radio>
              <Radio value="private">Escola Privada</Radio>
            </Stack>
          </RadioGroup>
        </Box>

        {type === "public" && renderPublic()}
        {type === "private" && renderPrivate()}

        {/* <Box width={"100%"}>
          <label>Comentários</label>
          <Textarea
            name={"comments"}
            value={comments}
            onChange={handleChange}
            placeholder="Comentários adicionais à reserva"
          />
        </Box> */}
      </VStack>
    </Box>
  );
};

export default School;
