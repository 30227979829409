import React, { useState, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { Box, Button, Link } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import axios from "axios";

// import { setGlobalState, getGlobalState, useGlobalState } from "../store";

import ContentSide from "../components/ContentSide/ContentSide";
import Card from "../components/Card/Card";
import Progress from "../components/Progress/Progress";
import SchoolTitle from "../components/SchoolTitle/SchoolTitle";
import Thankyou from "../components/Activity/Thankyou";
import Confirm from "../components/Activity/Confirm";

//Forms
import PersonalDetails from "../components/Forms/PersonalDetails/PersonalDetails";
import Students from "../components/Forms/Students/Students";
import Material from "../components/Forms/Material/Material";
import Horta from "../components/Forms/Horta/Horta";
import History from "../components/Forms/History/History";
import Curricular from "../components/Forms/Curricular/Curricular";
import Volunteer from "../components/Forms/Volunteer/Volunteer";

import Navigation from "../components/Forms/Navigation/Navigation";
import School from "../components/Forms/School/School";
import Summary from "../components/Forms/Summary/Summary";

const Main = (props) => {
  const toast = useToast();
  const { token, registrationId } = useParams();
  const [seenIntro, setSeenIntro] = useState(false);

  /**
   * State set
   */
  const [details, setDetails] = useState({
    school: {
      school: "",
      type: "public",
      agrupamento: "",
    },
    responsible: [
      {
        name: "",
        vat: "",
        surname: "",
        phone: "",
        email: "",
      },
    ],
    students: [
      {
        name: "",
        surname: "",
        phone: "",
        email: "",
      },
    ],
    material: [],
    horta: { area: "", slots: "", tutors: "", list: [] },
    history: "",
    curricular: "",
    book: "",
    volunteer: "",
    observations: "",
    owner: {
      admin: {
        name: "",
        email: "",
      },
      person: {
        name: "",
        email: "",
      },
      privacy: false,
    },
  });

  const defaultData = [
    {
      title: "Estabelecimento de Ensino",
      description: "Utilize a caixas de seleção para escolher a escola",
      image: "/images/2.jpg",
    },
    {
      title: "Responsáveis",
      description: "Preencha os dados dos responsáveis da horta da escola",
      image: "/images/3.jpg",
    },
    {
      title: "Formandos (Opcional)",
      description: `Formação: "Princípios para a instalação de uma Horta Biológica na Escola" - duração 25h - de 08/02/2023 a 25/03/2023.
      <br/><br/>Este registo serve como pré-inscrição na formação, sendo que é opcional e apenas garantismos uma vaga por escola. 
      <br/><br/>A formalização desta inscrição é posteriormente feita através do site do CFC (Centro de Formação de Cascais) de 21/11/2022 a 21/12/2022.
      `,
      image: "/images/4.jpg",
    },
    {
      title: "Material",
      description: `Deverão selecionar o(s) apoio(s) que pretendem para a escola.<br/><br/> Os recursos aqui requisitados serão fornecidos apenas em Fevereiro (aquando a época de plantação das culturas de primavera/verão)`,
      image: "/images/10.jpg",
    },
    {
      title: "Informação da Horta",
      description: "Precisamos de mais informações sobre a horta",
      // image: "/images/informacao.png",
      image: "/images/6.jpg",
    },
    {
      title: "História da Horta",
      description:
        "Escrever um breve texto sobre a horta: tempo de existência, os promotores, os utilizadores, comunidade envolvida (pais, entidades externas) e dinâmicas desenvolvidas",
      // image: "/images/historia.png",
      image: "/images/7.jpg",
    },
    {
      title: "Atividades de âmbito curricular",
      description:
        "Identificar as Atividades que pretendem dinamizar e as respetivas Áreas Curriculares",
      // image: "/images/historia.png",
      image: "/images/8.jpg",
    },
    // {
    //   title: "Caderno da Horta Biológica na Escola",
    //   description:
    //     "Versão online: https://ambiente.cascais.pt/terrasdecascais/hortas-nas-escolas ",
    //   image: "/images/caderno.png",
    // },
    {
      title: "Voluntariado",
      description: `A Divisão de Cidadania e Participação da CMC possui um Programa de Voluntariado, o qual engloba a possibilidade de participarem nas Hortas nas Escolas.
<br/><br/>
        A aceitação deste voluntário implica que consigam coordenar esse voluntário na marcação das visitas, actividades com os alunos, definição das tarefas na horta, etc. 
        `,
      // image: "/images/senior.png",
      image: "/images/9.jpg",
    },
    {
      title: "Termo de responsabilidade",
      description: `Na sequência da aprovação desta candidatura pela CMC, a Escola compromete-se a:
      <br/><br/>
      1. Cumprir as obrigações decorrentes do Normativo do Projeto;<br/>
      2. Inscrever o projeto Hortas nas Escolas no Plano Anual de Atividades;<br/>
      3. Receber visitas de acompanhamento de representantes do Gabinete de Educação e da Cascais Ambiente; `,
      // image: "/images/summary.png",
      image: "/images/10.jpg",
    },
  ];

  const [step, setCurrStep] = useState(0);
  const [saved, setSaved] = useState(false);
  const [activityImage, setActivityImage] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loggedIn, setLoggedIn] = useState(null);

  useEffect(() => {
    if (registrationId) {
      fetchData(registrationId);
    }
    return () => {};
  }, [registrationId]);

  /**
   * Get booking data
   * @param {*} bookid
   */
  const fetchData = async (bookid) => {
    try {
      if (!bookid) throw new Error("no id defined");
      const bookingData = await axios.get(`/schools/${bookid}`);
      if (!bookingData.data || !bookingData?.data?.msg) {
        throw new Error("no data");
      }
      const BDMsg = bookingData?.data?.msg;
      const BDResp = bookingData?.data?.responsibles;

      setDetails({
        school: {
          school: BDMsg.school || "",
          type: BDMsg.schooltype || "public",
          agrupamento: BDMsg.agrupamento || "",
        },
        responsible: BDResp || [
          {
            name: "",
            vat: "",
            surname: "",
            phone: "",
            email: "",
          },
        ], //need to get Resonposible from BD
        students: BDMsg.students || [
          {
            name: "",
            surname: "",
            phone: "",
            email: "",
          },
        ],
        // material: BDMsg.material || [],
        horta: {
          area: BDMsg.area || "",
          slots: BDMsg.slots || "",
          tutors: BDMsg.tutors || "",
          list: BDMsg.hortaattr || [],
        },
        material: [],
        history: BDMsg.history || "",
        curricular: BDMsg.curricular || "",
        book: BDMsg.book || "",
        volunteer: BDMsg.volunteer || "",
        observations: BDMsg.observations || "",
        owner: {
          admin: BDMsg.admin || {
            name: "",
            email: "",
          },
          person: {
            name: BDResp[0]?.name || "",
            email: BDResp[0]?.email || "",
          }, //need to get person from BD
          privacy: false,
        },
      });

      // setActivitiesToList(bookingData?.data?.data);
    } catch (error) {
      toast({
        title: "Esta inscrição, não existe ou já foi confimada",
        status: "error",
      });
      console.log(error);
    }
  };

  /**
   * Submit reservation
   */

  const submitReservation = async () => {
    if (
      details.owner.admin.name === "" ||
      details.owner.admin.email === "" ||
      details.owner.person.name === "" ||
      details.owner.person.email === "" ||
      !details.owner.privacy ||
      details.owner.privacy === false
    ) {
      toast({
        title: "Preencha todos os campos obrigatórios",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return false;
    }
    try {
      const setData = await axios.post(`/schools`, {
        ...details,
        // myCascais: loggedIn && loggedIn.myCascais,
      });
      if (setData?.data?.status === "OK") {
        toast({
          title: "Reserva registada com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setSaved(true);
      } else {
        toast({
          title:
            "Aconteceu um erro a salvar a sua reserva, por-favor tente novamente",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      setSaving(false);
    } catch (error) {
      console.log(error);
      setSaving(false);
    }
  };

  //loginMycascais
  // useEffect(() => {
  //   async function getLogin() {
  //     //handle login
  //     if (!loggedIn && window.MyCascaisCookie) {
  //       const response = await axios.post(`/auth/mycascais/`, {
  //         tid: window.MyCascaisCookie,
  //       });
  //       if (response.data && response.data.token) {
  //         const userData = response.data;
  //         setLoggedIn(userData);
  //         setDetails({
  //           ...details,
  //           personalDetails: {
  //             name: userData.firstName,
  //             vat: userData.vat,
  //             surname: userData.lastName,
  //             address: userData.address,
  //             city: userData.city,
  //             country: userData.country,
  //             pobox: userData.poBox,
  //           },
  //           contacts: {
  //             ...details.contacts,
  //             email: userData.email,
  //             phone: userData.phone,
  //           },
  //         });
  //       } else {
  //         setLoggedIn({
  //           status: "New user",
  //           myCascais: response?.data?.myCascais,
  //         });
  //       }

  //       //handle logout
  //     } else if (loggedIn && !window.MyCascaisCookie) {
  //       setLoggedIn(null);
  //     }
  //   }
  //   getLogin();
  //   return () => {};
  // }, [toast, loggedIn]);

  /**
   * Get activity data
   */
  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       if (!id) throw new Error("no id defined");
  //       const activityData = await axios.get(`booking/activity/${id}`);
  //       if (!activityData.data) throw new Error("no data");
  //       setActivity(activityData.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   fetchData();
  // }, [id]);

  /**
   * check mandatory fields before continue
   * @returns
   */
  const setStep = (val) => {
    //if we go back
    if (val < step) {
      setCurrStep(val);
      return true;
    }
    //if forward
    if (step === 0) {
      if (
        details.school.year === "" ||
        details.school.school === "" ||
        details.school.type === "" ||
        details.school.className === "" ||
        details.school.slots === ""
      ) {
        toast({
          title: "Preencha todos os campos obrigatórios",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setCurrStep(val);
      }
    } else if (step === 1) {
      let validator = false;
      details.responsible.forEach((item) => {
        if (
          (item.name === "" ||
            item.surname === "" ||
            item.vat === "" ||
            item.phone === "" ||
            item.email === "") &&
          !validator
        ) {
          validator = true;
        }
      });
      if (validator) {
        toast({
          title: "Preencha todos os campos obrigatórios",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setCurrStep(val);
      }
    } else if (step === 2) {
      // let validator = false;
      // details.students.forEach((item) => {
      //   if (
      //     (item.name === "" ||
      //       item.surname === "" ||
      //       item.phone === "" ||
      //       item.email === "") &&
      //     !validator
      //   ) {
      //     validator = true;
      //   }
      // });
      // if (validator) {
      //   toast({
      //     title: "Preencha todos os campos obrigatórios",
      //     status: "error",
      //     duration: 9000,
      //     isClosable: true,
      //   });
      // } else {
      setCurrStep(val);
      // }
    } else if (step === 3) {
      // if (details.material && details.material.length < 1) {
      //   toast({
      //     title: "Preencha todos os campos obrigatórios",
      //     status: "error",
      //     duration: 9000,
      //     isClosable: true,
      //   });
      // } else {
      //   setCurrStep(val);
      // }
      setCurrStep(val);
    } else if (step === 4) {
      if (
        details.horta.area === "" ||
        details.horta.slots === "" ||
        details.horta.tutors === ""
      ) {
        toast({
          title: "Preencha todos os campos obrigatórios",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setCurrStep(val);
      }
    } else if (step === 5) {
      setCurrStep(val);
      // if (details.history === "") {
      //   toast({
      //     title: "Preencha todos os campos obrigatórios",
      //     status: "error",
      //     duration: 9000,
      //     isClosable: true,
      //   });
      // } else {
      //   setCurrStep(val);
      // }
    } else if (step === 6) {
      setCurrStep(val);
      // if (details.curricular === "") {
      //   toast({
      //     title: "Preencha todos os campos obrigatórios",
      //     status: "error",
      //     duration: 9000,
      //     isClosable: true,
      //   });
      // } else {
      //   setCurrStep(val);
      // }
    } else if (step === 7) {
      if (details.volunteer === "") {
        toast({
          title: "Preencha todos os campos obrigatórios",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setCurrStep(val);
      }
    } else if (step === 8) {
      if (details.summary === "") {
        toast({
          title: "Preencha todos os campos obrigatórios",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setCurrStep(val);
      }
    } else if (step === 9) {
      if (
        details.owner.admin.name === "" ||
        details.owner.admin.email === "" ||
        details.owner.person.name === "" ||
        details.owner.person.email === "" ||
        !details.owner.privacy ||
        details.owner.privacy === false
      ) {
        toast({
          title: "Preencha todos os campos obrigatórios",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setCurrStep(val);
      }
    }
  };
  /**
   * Switch between form steps
   * @returns
   */
  const formStep = () => {
    return {
      0: (
        <Card>
          <School details={details} setDetails={setDetails} />
          <Navigation step={step} setStep={setStep}>
            Escolher Responsáveis
          </Navigation>
        </Card>
      ),
      1: (
        <>
          <PersonalDetails details={details} setDetails={setDetails} />
          <Navigation step={step} setStep={setStep}>
            Escolher Formandos
          </Navigation>
        </>
      ),
      2: (
        <>
          <Students
            details={details}
            setDetails={setDetails}
            // setActivityImage={setActivityImage}
          />
          <Navigation step={step} setStep={setStep}>
            Escolher Material
          </Navigation>
        </>
      ),
      3: (
        <>
          <Material details={details} setDetails={setDetails} />
          <Navigation step={step} setStep={setStep}>
            Informação sobre a Horta
          </Navigation>
        </>
      ),
      4: (
        <>
          <Horta details={details} setDetails={setDetails} />
          <Navigation step={step} setStep={setStep}>
            Historia da Horta
          </Navigation>
        </>
      ),

      5: (
        <>
          <History details={details} setDetails={setDetails} />
          <Navigation step={step} setStep={setStep}>
            Atividades Corriculares
          </Navigation>
        </>
      ),
      // 6: (
      //   <>
      //     <Book details={details} setDetails={setDetails} />
      //     <Navigation step={step} setStep={setStep}>
      //       Voluntariado Sénior
      //     </Navigation>
      //   </>
      // ),
      6: (
        <>
          <Curricular details={details} setDetails={setDetails} />
          <Navigation step={step} setStep={setStep}>
            Voluntários
          </Navigation>
        </>
      ),
      7: (
        <>
          <Volunteer details={details} setDetails={setDetails} />
          <Navigation step={step} setStep={setStep}>
            Termo de responsabilidade
          </Navigation>
        </>
      ),
      8: (
        <>
          <Summary details={details} setDetails={setDetails} />
          <Navigation
            step={step}
            setStep={setStep}
            onClick={() => {
              if (!saving) {
                setSaving(true);
                submitReservation();
              } else {
                toast({
                  title: "A reserva já está a ser submetida, por favor aguarde",
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                });
              }
            }}
          >
            {saving ? "A salvar..." : "Enviar Registo"}
          </Navigation>
        </>
      ),
    };
  };

  // const loginMyCascais = async (e) => {
  //   try {
  //     if (!window.MyCascaisLogin)
  //       toast({
  //         title: "My cascais not available",
  //         status: "warning",
  //         position: "bottom",
  //       });
  //     window.location = window.MyCascaisLogin;
  //   } catch (e) {}
  // };

  /**
   * show saved data
   */
  if (token) {
    return (
      <ContentSide image={details.school && details.school.image}>
        <Box className={"CAlogo"} />
        <Box width={"100%"} padding={"2em 0"}>
          <Confirm token={token} />
        </Box>
      </ContentSide>
    );
  }

  if (saved) {
    return (
      <ContentSide image={details.school && details.school.image}>
        <Box className={"CAlogo"} />
        <Box width={"100%"} padding={"2em 0"}>
          <Thankyou />
        </Box>
      </ContentSide>
    );
  }

  if (!seenIntro) {
    return (
      <ContentSide image={"/images/1.jpg"}>
        <Box className={"CAlogo"} />

        <Box width={"100%"}>
          <Card margin={"1em 0"}>
            <Box className={"intro"} textAlign={"left"} mb={"1em"}>
              <Link
                href={"/images/2022_FLYER_DIGITAL_HORTAS_ESCOLAS.pdf"}
                target={"_blank"}
              >
                <img src={"/images/pdfPreview.png"} width={"100%"} />
              </Link>
              <Box fontWeight={"bold"} fontSize={"1.2em"}>
                Como funciona?
              </Box>
              <p>
                Todos os anos, até ao final de outubro, as escolas inscrevem-se
                para que possam beneficiar de acompanhamento (apoio técnico),
                alguns recursos (plantas, fertilizantes, ferramentas, sistema de
                rega, etc.) e acesso à formação creditada.
              </p>
            </Box>
          </Card>
          <Card margin={"1em 0"}>
            <Box className={"intro"} textAlign={"left"} mb={"1em"}>
              <Box fontWeight={"bold"} fontSize={"1.2em"}>
                Recursos
              </Box>
              <p>
                As <strong>Escolas de Continuidade</strong>, no final do ano
                letivo (Junho), têm a possibilidade de pré-reservar as
                Hortícolas de Outono/Inverno que recebem no ano letivo seguinte,
                em Outubro (1º Fornecimento).
              </p>
              <br />
              <p>
                As <strong>Escolas Novas</strong>, como apenas se inscrevem em
                Outubro, apenas recebem os recursos relativos ao 2º
                Fornecimento, em Fevereiro, nomeadamente as Hortícolas de
                Primavera/Verão e os restantes materiais requisitados na
                inscrição.
              </p>
            </Box>
          </Card>

          <Card margin={"1em 0"}>
            <Box className={"intro"} textAlign={"left"} mb={"1em"}>
              <Box fontWeight={"bold"} fontSize={"1.2em"}>
                Datas importantes
              </Box>
              <ul>
                <li>
                  <strong>1º Fornecimento</strong>
                  <ul>
                    <li>
                      Cava – <strong>25 Setembro a 06 Outubro</strong>
                    </li>
                    <li>
                      Fertilizantes e Plantas – <strong>09 a 13 Outubro</strong>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Inscrição</strong> no programa das Hortas nas Escolas
                  - ano letivo -{" "}
                  <ul>
                    <li>
                      <strong>13 Setembro a 22 Outubro</strong>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>1ª Ronda de Visitas</strong>
                  <ul>
                    <li>
                      Escolas Continuidade{" "}
                      <strong>18 Setembro a 29 Outubro</strong>
                    </li>
                    <li>
                      Escolas Novas <strong>23 Outubro a 03 Novembro</strong>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>2º Fornecimento</strong>
                  <ul>
                    <li>
                      Cava –<strong>12 a 23 de Fevereiro</strong>
                    </li>
                    <li>
                      Fertilizantes, Plantas, Ferramentas e Sistema de Rega –
                      <strong>26 Fevereiro a 1 Março</strong>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Formação creditada (25h)</strong>
                  <ul>
                    <li>
                      <strong>10 Janeiro a 23 Março</strong>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>2ª Ronda de Visitas</strong>
                  <ul>
                    <li>
                      <strong>Março e Abril</strong>
                    </li>
                  </ul>
                </li>
                <li>
                  <strong>Momento de Partilha</strong>
                  <ul>
                    <li>
                      <strong>18 de Maio</strong>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Pré-inscrição</strong> no programa das Hortas nas
                  Escolas - ano letivo 2024/2025
                  <ul>
                    <li>
                      <strong>20 de Maio a 23 de Junho</strong>
                    </li>
                  </ul>
                </li>
              </ul>
            </Box>
          </Card>
          <Card margin={"1em 0"}>
            <Box className={"intro"} textAlign={"left"} mb={"1em"}>
              <Box fontWeight={"bold"} fontSize={"1.2em"}>
                Caderno da Horta Biológica na Escola
              </Box>
              <p>
                Para além da formação creditada (opcional), possuímos o{" "}
                <strong>Caderno da Horta Biológica na Escola</strong> que
                orienta o aluno e o professor ao longo do ano na horta:
              </p>
              <br />
              <Link
                href={
                  "https://ambiente.cascais.pt/pt/terrasdecascais/caderno-das-hortas"
                }
                target={"_blank"}
              >
                <Button
                  mt={".8em"}
                  cursor={"pointer"}
                  fontSize={".8em"}
                  textAlign={"center"}
                  border={"#599331 1px solid"}
                  color={"#599331"}
                  bg={"#fff"}
                >
                  Versão online gratuita
                </Button>
              </Link>
              <br />
              <br />
              <p>
                <strong>Vol 1 -</strong> preparação do projecto da horta
              </p>
              <p>
                <strong>Vol 2 -</strong> 10 fichas teórico-práticas para os 10
                meses do ano lectivo, que orientam o aluno no sentido de
                executar a horta, acompanhado de conteúdos curriculares
                específicos
              </p>
              <p>
                <strong>Vol 3 -</strong> são as mesmas 10 fichas, mas para o
                professor, com os materiais que tem de fornecer ao aluno para
                executar a parte prática e os conteúdos teóricos (matemática,
                ciências, etc.) que o aluno aprende
              </p>
            </Box>
          </Card>

          <Button
            onClick={(e) => setSeenIntro(true)}
            w="100%"
            mt={".8em"}
            cursor={"pointer"}
            fontSize={".8em"}
            textAlign={"center"}
            bg={"#599331"}
            color={"white"}
          >
            Começar a inscrição
          </Button>
        </Box>
      </ContentSide>
    );
  }

  /**
   * If no Activity is selected, show NoActivity component
   */
  // if (
  //   !window.MyCascaisCookie &&
  //   (!loggedIn || (loggedIn && loggedIn !== "New user"))
  // ) {
  //   return (
  //     <ContentSide
  //       image={
  //         activityImage ? activityImage : details.school && details.school.image
  //       }
  //     >
  //       <Box width={"100%"} padding={"0 2em"} textAlign={"center"}>
  //         <Box className="activity-overview">
  //           <h2>Autenticação MyCascais</h2>
  //         </Box>
  //       </Box>
  //       <Box width={"100%"}>
  //         <Card>
  //           <Box className={"local"} textAlign={"center"}>
  //             Para proceder com o pedido, é necessário autenticar-se com o
  //             serviço MyCascais.
  //           </Box>
  //           <Button
  //             onClick={(e) => loginMyCascais(e)}
  //             type="submit"
  //             bg="#000"
  //             w="100%"
  //             h="45"
  //             mb="5px"
  //             mt={"1em"}
  //             color="white"
  //             _hover={{
  //               bg: "teal.200",
  //             }}
  //             _active={{
  //               bg: "teal.400",
  //             }}
  //           >
  //             Entrar com My Cascais
  //           </Button>
  //         </Card>
  //       </Box>
  //     </ContentSide>
  //   );
  // }

  /**
   * Lets render the form based on the step
   */

  return (
    <ContentSide image={defaultData[step].image}>
      <Box className={"CAlogo"} />
      <Box width={"100%"} padding={"0 2em"}>
        <Progress step={step} setStep={setStep} />
        <SchoolTitle
          school={
            details.school && details.school.school !== "" ? details.school : ""
          }
          content={defaultData[step]}
        />
      </Box>
      <Box width={"100%"}>{formStep()[step]}</Box>
    </ContentSide>
  );
};

export default Main;
