import React from "react";
import "./styles.scss";
import { Box } from "@chakra-ui/react";
import Card from "../Card/Card";

const Button = ({ color, children, onClick, noHover }) => {
  return (
    <Box
      className={`button ${noHover ? "noHover" : ""} ${color ? color : ""}`}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export default Button;
