import React from "react";
import { Box, HStack, useToast } from "@chakra-ui/react";
import {
  AiOutlineCloseCircle,
  AiOutlineCheckCircle,
  AiOutlineClockCircle,
} from "react-icons/ai";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import IconBox from "../../IconBox/IconBox";

const Volunteer = ({ setDetails, details }) => {
  const toast = useToast();
  const [animationParent] = useAutoAnimate();
  const { volunteer } = details;

  const handleChange = (val) => {
    setDetails({
      ...details,
      volunteer: val,
    });
  };
  return (
    <Box width={"100%"} ref={animationParent} className={"buttonVol"}>
      <HStack mb={"2em"} flexDir={["column", "row"]}>
        <Box
          width={{ base: "100%", md: "48%" }}
          mb={["1em", "0"]}
          className={`buttonCard ${details.volunteer === "yes" && "active"}`}
          onClick={() => handleChange("yes")}
        >
          <Box fontWeight={"bold"}>Sim</Box>
          <Box>
            <IconBox icon={<AiOutlineCheckCircle />} />
          </Box>
        </Box>
        <Box
          width={{ base: "100%", md: "48%" }}
          className={`buttonCard ${details.volunteer === "no" && "active"}`}
          onClick={() => handleChange("no")}
        >
          <Box fontWeight={"bold"}>Não</Box>
          <Box>
            <IconBox icon={<AiOutlineCloseCircle />} />
          </Box>
        </Box>
        <Box
          width={{ base: "100%", md: "48%" }}
          className={`buttonCard ${
            details.volunteer === "dontknow" && "active"
          }`}
          onClick={() => handleChange("dontknow")}
          mt={["1em!important", "0!important"]}
        >
          <Box fontWeight={"bold"}>Não sei</Box>
          <Box>
            <IconBox icon={<AiOutlineClockCircle />} />
          </Box>
        </Box>
      </HStack>
    </Box>
  );
};

export default Volunteer;
