import React from "react";
import { Box, Flex, useToast, Textarea, Tooltip } from "@chakra-ui/react";
import IconBox from "../../IconBox/IconBox";
import Card from "../../../components/Card/Card";

import {
  GiGroundSprout,
  GiFertilizerBag,
  GiFarmer,
  GiWaterDivinerStick,
} from "react-icons/gi";
import { BsFillCloudSunFill, BsFillSunFill } from "react-icons/bs";
import { TbShovel } from "react-icons/tb";
import { useAutoAnimate } from "@formkit/auto-animate/react";

const Material = ({ setDetails, details }) => {
  const toast = useToast();
  const [animationParent] = useAutoAnimate();
  const { observations } = details;
  console.log(details);
  const handleChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const materials = [
    {
      id: 9,
      name: "Apoio à Cava Outono (10 m2)",
      description: `Preparação de 10m2 de solo para a plantação de hortícolas`,
      points: 4,
      pointsText: "4 pontos",
      icon: <GiFarmer />,
    },
    {
      id: 10,
      name: "Fertilizantes Outono",
      description: `Composto proveniente de estrume de cavalo e um adubo orgânico granulado rico em azoto`,
      points: 1,
      pointsText: "1 ponto",
      icon: <GiFertilizerBag />,
    },
    {
      id: 11,
      name: "Hortícolas Outono",
      description: `Conjunto de sementes e plântulas de hortícolas de outono/inverno (ex. couve, ervilha, fava, alface, nabo, nabiça, etc.)`,
      points: 1,
      pointsText: "1 ponto",
      icon: <BsFillSunFill />,
    },
  ];

  const getSchoolPoints = () => {
    return details.points || 4;
  };

  // togle details materia array true false
  const toggleChange = (name) => {
    const schoolPoints = getSchoolPoints();
    if (details.material.includes(name)) {
      setDetails({
        ...details,
        material: details.material.filter((item) => item !== name),
      });
    } else {
      //lets check if we have less than 4 points
      let totalPoints =
        materials.find((item) => item.name === name)?.points || 0;

      details.material.map((val) => {
        totalPoints += materials.find((item) => item.name === val)?.points || 0;
        return val;
      });
      if (totalPoints <= schoolPoints) {
        setDetails({
          ...details,
          material: [...details.material, name],
        });
      }
    }
  };

  return (
    <Box width={"100%"} ref={animationParent}>
      <Card margin={"1em 0"}>
        <Box className={"intro"} textAlign={"left"} mb={"1em"}>
          <p>
            Os recursos da 1ª entrega são encomendados no final do ano letivo
            anterior (pré-inscrição) para serem fornecidos no início do ano
            letivo seguinte.{" "}
            <strong>
              Já os recursos da 2ª entrega são encomendados no início do ano
              letivo corrente (inscrição/reinscrição){" "}
            </strong>
            para serem fornecidos a meio desse mesmo ano letivo. <br />
            <br />
            Nesta pré-inscrição terão então a possibilidade de{" "}
            <strong>
              gastar antecipadamente alguns ou todos os pontos do próximo ano
              letivo
            </strong>
            , mas apenas nos recursos da 1ª entrega, que recebem em
            Setembro/Outubro. Isso significa que existe o compromisso dos
            recursos agora encomendados serem utilizados pela escola/instituição
            no início do ano letivo. Caso contrário a escola/instituição pode
            ser penalizada.
          </p>
        </Box>
      </Card>
      <Box padding={"1em 2em"}>
        <strong>
          Escolha os recursos que pretende receber em Setembro/Outubro
        </strong>
      </Box>

      <Flex
        flexWrap={"wrap"}
        display={"inline-flex"}
        gap={"1em"}
        w={"100%"}
        m="1em 0"
      >
        {materials.map((m, i) => {
          return (
            <Tooltip hasArrow label={m.description} aria-label="A tooltip">
              <Box
                key={i}
                width={{ base: "100%", md: "48%" }}
                className={`buttonCard ${
                  details?.material?.includes(m.name) && "active"
                }`}
                onClick={() => toggleChange(m.name)}
              >
                <Box fontWeight={"bold"}>
                  {m.name}

                  <Box fontSize={".8em"} opacity={"0.6"}>
                    {m.pointsText}
                  </Box>
                </Box>
                <Box>
                  <IconBox icon={m.icon} />
                </Box>
              </Box>
            </Tooltip>
          );
        })}
      </Flex>
      <Box className={"intro"} textAlign={"left"} mb={"1em"}>
        <Flex
          justifyContent={"space-between"}
          flexDirection={{ base: "column", md: "row" }}
        >
          <Box w={{ base: "100%", md: "48%" }}>
            <Card margin={"1em 0"}>
              <strong>1ª entrega - Setembro/Outubro</strong>
              <ul>
                <li>1 pto – Hortícolas Outono</li>

                <li>1 pto – Fertilizantes</li>

                <li>4 pto – Apoio à Cava</li>
              </ul>
              <br />
            </Card>
          </Box>
          <Box w={{ base: "100%", md: "48%" }}>
            <Card margin={"1em 0"}>
              <strong>2ª entrega - Fevereiro/Março</strong>
              <ul>
                <li>1 pto – Hortícolas Primavera</li>
                <li>1 pto – Fertilizantes</li>
                <li>1 pto – Aromáticas e Flores Comestíveis</li>
                <li>1 pto - Ferramentas</li>
                <li>3 pto – Sistema rega gota-a-gota</li>
              </ul>
            </Card>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default Material;
