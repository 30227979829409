import { Box, useToast, Button, Flex } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// import { setGlobalState, getGlobalState, useGlobalState } from "../store";

import Card from "../components/Card/Card";
import ContentSide from "../components/ContentSide/ContentSide";

//Forms
import PersonalDetails from "../components/Forms/PersonalDetails/PersonalDetails";
import Students from "../components/Forms/Students/Students";

import Navigation from "../components/Forms/Navigation/Navigation";
import School from "../components/Forms/School/School";
import Material from "../components/Pre/Material/Material";
import Thankyou from "../components/Pre/Thankyou/Thankyou";

const PreInscricao = (props) => {
  const toast = useToast();
  const { token, registrationId } = useParams();
  const [seenIntro, setSeenIntro] = useState(false);

  const [step, setCurrStep] = useState(0);
  const [saved, setSaved] = useState(false);
  const [saving, setSaving] = useState(false);

  const stopPreInscricao = false;
  /**
   * State set
   */
  const [details, setDetails] = useState({});

  useEffect(() => {
    if (registrationId) {
      fetchData(registrationId);
    }
    return () => {};
  }, [registrationId]);

  /**
   * Get booking data
   * @param {*} bookid
   */
  const fetchData = async (bookid) => {
    try {
      if (!bookid) throw new Error("no id defined");
      const bookingData = await axios.get(`/schools/${bookid}`);
      if (!bookingData.data || !bookingData?.data?.msg) {
        throw new Error("no data");
      }
      setDetails(bookingData?.data?.msg);

      // setActivitiesToList(bookingData?.data?.data);
    } catch (error) {
      toast({
        title: "Esta inscrição, não existe ou já foi confimada",
        status: "error",
      });
      console.log(error);
    }
  };

  /**
   * Submit reservation
   */

  const submitReservation = async (status = 4) => {
    try {
      const setData = await axios.post(`/schools/pre/${registrationId}`, {
        ...details,
        status,
        // myCascais: loggedIn && loggedIn.myCascais,
      });
      if (setData?.data?.status === "OK") {
        toast({
          title: "Reserva registada com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        if (!seenIntro) {
          setSeenIntro(true);
        }
        setCurrStep(1);
        setSaved(true);
      } else {
        toast({
          title:
            "Aconteceu um erro a salvar a sua reserva, por-favor tente novamente",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      setSaving(false);
    } catch (error) {
      console.log(error);
      setSaving(false);
    }
  };

  /**
   * check mandatory fields before continue
   * @returns
   */
  const setStep = (val) => {
    //if we go back
    if (val < step) {
      setCurrStep(val);
      return true;
    }
    //if forward
    setCurrStep(val);
  };
  /**
   * Switch between form steps
   * @returns
   */
  const formStep = () => {
    return {
      0: (
        <>
          <Material details={details} setDetails={setDetails} />
          <Navigation
            step={step}
            setStep={setStep}
            onClick={() => {
              if (!saving) {
                setSaving(true);
                submitReservation();
              } else {
                toast({
                  title: "A reserva já está a ser submetida, por favor aguarde",
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                });
              }
            }}
          >
            {saving ? "A salvar..." : "Concluir a pré-inscrição"}
          </Navigation>
        </>
      ),
      1: (
        <>
          <Card>
            <Thankyou details={details} setDetails={setDetails} />
          </Card>
        </>
      ),
    };
  };

  if (stopPreInscricao) {
    return (
      <ContentSide image={"/images/1.jpg"}>
        <Box className={"CAlogo"} />
        <Box width={"100%"}>
          <Card margin={"1em 0"}>
            <Box
              lineHeight={1}
              fontSize={"1.3em"}
              fontWeight={"bold"}
              color={"#599331"}
            >
              Pré-inscrição esta encerrada
            </Box>{" "}
            <br />
            <p>
              pré-inscrição no programa das Hortas nas Escolas encontra-se
              encerrada. Qualquer dúvida contactar Miguel Brito - 910 177 643 –
              miguel.brito@cascaisambiente.pt
            </p>
          </Card>
        </Box>
      </ContentSide>
    );
  }

  if (!details?.school) {
    return (
      <ContentSide image={"/images/1.jpg"}>
        <Box className={"CAlogo"} />
        <Box width={"100%"}>
          <Card margin={"1em 0"}>
            <Box
              lineHeight={1}
              fontSize={"1.3em"}
              fontWeight={"bold"}
              color={"#599331"}
            >
              Pré-registo da sua escola/instituição
            </Box>{" "}
            <br />
            <p>
              A sua inscrição, não está ativa neste momento, um dos responsabeis
              da sua escola/instituição, já respondeu a este pré-registo
            </p>
          </Card>
        </Box>
      </ContentSide>
    );
  }

  if (!seenIntro && details?.school) {
    return (
      <ContentSide image={"/images/1.jpg"}>
        <Box className={"CAlogo"} />

        <Box width={"100%"}>
          <Card margin={"1em 0"}>
            <Box className={"intro"} textAlign={"left"} mb={"1em"}>
              <Box fontWeight={"bold"} fontSize={"1.4em"}>
                {details?.school}
              </Box>
              <Box fontWeight={"bold"} fontSize={"1.2em"}>
                Pré-registo da sua escola/instituição
              </Box>
              <br />
              <p>
                Vimos por este meio convidá-lo(a) a fazer o pré-registo da sua
                escola/instituição no programa das Hortas nas Escolas do próximo
                ano letivo e selecionar os recursos que pretende receber em
                Setembro/Outubro.
              </p>
              <br />
              <p>
                Todos os anos as Escolas/Instituições possuem ao seu dispor um
                total de 4 pontos por ano mais 1 possível ponto extra, atribuído
                como prémio às escolas/instituições que ou participaram no
                Momento de Partilha ou entregaram o relatório do ano letivo
                anterior. Esses 4 (possivelmente 5) pontos podem ser utilizados
                nos seguintes recursos:
              </p>
              <Button
                onClick={(e) => setSeenIntro(true)}
                w="100%"
                mt={".8em"}
                cursor={"pointer"}
                textAlign={"center"}
                bg={"#599331"}
                color={"white"}
                size={"lg"}
              >
                Sim quero fazer o pré-registo
              </Button>
              <Button
                onClick={(e) => {
                  if (!saving) {
                    setSaving(true);
                    submitReservation(5);
                  } else {
                    toast({
                      title:
                        "A reserva já está a ser submetida, por favor aguarde",
                      status: "error",
                      duration: 9000,
                      isClosable: true,
                    });
                  }
                }}
                w="100%"
                mt={".8em"}
                cursor={"pointer"}
                textAlign={"center"}
                size={"md"}
                colorScheme={"telegram"}
                variant="outline"
              >
                Não quero fazer o pré-registo
              </Button>
              <br />
              <br />
              <p>
                <strong>Prazo limite para a pré-inscrição: 23 de Junho</strong>
              </p>
            </Box>
          </Card>
        </Box>
      </ContentSide>
    );
  }

  return (
    <ContentSide image={details.school && details.school.image}>
      <Box className={"CAlogo"} />
      <Box width={"100%"} padding={"2em 0"}>
        {/* <Confirm token={registrationId} /> */}
        {formStep()[step]}
      </Box>
    </ContentSide>
  );
};

export default PreInscricao;
