import React from "react";
import { Box } from "@chakra-ui/react";
import Card from "../Card/Card";
import ContentSide from "../ContentSide/ContentSide";
import Button from "../Button/Button";
import { publicURL } from "../../config/config";
import { HiOutlineDocumentAdd } from "react-icons/hi";
import IconBox from "../IconBox/IconBox";

const Thankyou = () => {
  return (
    <Box width={"100%"} padding={"0 2em"} mt={"10vh"}>
      <Box
        lineHeight={"1"}
        fontWeight={"bold"}
        color={"#599331"}
        fontSize={"2.5em"}
        mb={".5em"}
      >
        Muito obrigado, estamos a confirmar a sua reserva...
      </Box>
      <Card>
        <Box mb={"2em"}>
          <p>
            A nossa equipa irá validar a sua reserva. Verifique o seu email com
            a confirmação da reserva.
          </p>
          <p>aproveite e descarregue já os Cadernos</p>
        </Box>
        <Button
          color={"green"}
          onClick={() =>
            (window.location =
              "https://ambiente.cascais.pt/terrasdecascais/hortas-nas-escolas")
          }
        >
          <Box>Caderno da Horta Biológica na Escola</Box>
          <IconBox icon={<HiOutlineDocumentAdd />} />
        </Button>
      </Card>
    </Box>
  );
};

export default Thankyou;
