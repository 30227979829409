import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Box, Flex } from "@chakra-ui/react";
import Button from "../Button/Button";
import IconBox from "../IconBox/IconBox";
import { MdOutlineFormatAlignRight } from "react-icons/md";
const Progress = ({ step, setStep }) => {
  return (
    <Flex
      width={"100%"}
      alignItems={"center"}
      justifyContent={"space-between"}
      flexDir={["column", "row"]}
    >
      <Box width={["100%", "40%"]}>
        <ProgressBar
          percent={(step * 100) / 8}
          //   filledBackground="#599331"
          filledBackground="linear-gradient(to right, #599331, #84bd5d)"
        >
          <Step transition="scale">
            {({ accomplished }) => (
              <Box
                onClick={() => setStep(0)}
                w={"20px"}
                h={"20px"}
                cursor={"pointer"}
                background={accomplished ? "#599331" : "rgba(211, 211, 211, 1)"}
                borderRadius={"50%"}
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <Box
                onClick={() => step >= 1 && setStep(1)}
                w={"20px"}
                h={"20px"}
                cursor={"pointer"}
                background={accomplished ? "#599331" : "rgba(211, 211, 211, 1)"}
                borderRadius={"50%"}
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <Box
                onClick={() => step >= 2 && setStep(2)}
                cursor={"pointer"}
                w={"20px"}
                h={"20px"}
                background={accomplished ? "#599331" : "rgba(211, 211, 211, 1)"}
                borderRadius={"50%"}
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <Box
                onClick={() => step >= 3 && setStep(3)}
                cursor={"pointer"}
                w={"20px"}
                h={"20px"}
                background={accomplished ? "#599331" : "rgba(211, 211, 211, 1)"}
                borderRadius={"50%"}
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <Box
                w={"20px"}
                onClick={() => step >= 4 && setStep(4)}
                cursor={"pointer"}
                h={"20px"}
                background={accomplished ? "#599331" : "rgba(211, 211, 211, 1)"}
                borderRadius={"50%"}
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <Box
                w={"20px"}
                onClick={() => step >= 5 && setStep(5)}
                cursor={"pointer"}
                h={"20px"}
                background={accomplished ? "#599331" : "rgba(211, 211, 211, 1)"}
                borderRadius={"50%"}
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <Box
                w={"20px"}
                onClick={() => step >= 6 && setStep(6)}
                cursor={"pointer"}
                h={"20px"}
                background={accomplished ? "#599331" : "rgba(211, 211, 211, 1)"}
                borderRadius={"50%"}
              />
            )}
          </Step>
          <Step transition="scale">
            {({ accomplished }) => (
              <Box
                w={"20px"}
                onClick={() => step >= 7 && setStep(7)}
                cursor={"pointer"}
                h={"20px"}
                background={accomplished ? "#599331" : "rgba(211, 211, 211, 1)"}
                borderRadius={"50%"}
              />
            )}
          </Step>
        </ProgressBar>
      </Box>
      <Box width={["100%", "40%"]} mt={["2em", "0"]}>
        <Button noHover={true}>
          {step === 0 && "Escolha Escola"}
          {step === 1 && "Responsáveis"}
          {step === 2 && "Formandos"}
          {step === 3 && "Material"}
          {step === 4 && "Inf. da Horta"}
          {step === 5 && "Histórico"}
          {/* {step === 6 && "Caderno da Horta"} */}
          {step === 6 && "Curricular"}
          {step === 7 && "Voluntariado"}
          {step === 8 && "Responsabilidade"}
          <IconBox icon={<MdOutlineFormatAlignRight />} />
        </Button>
      </Box>
    </Flex>
  );
};

export default Progress;
