import React from "react";
import { Input, Box, HStack, VStack } from "@chakra-ui/react";
import { countries } from "../../../config/countries";
import Card from "../../../components/Card/Card";
import IconBox from "../../IconBox/IconBox";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import "./styles.scss";

const PersonalDetails = ({ setDetails, details }) => {
  const [animationParent] = useAutoAnimate();
  const list = details.responsible;

  const handleChange = (e) => {
    const elementID = e.target?.dataset?.elemid || 0;
    setDetails({
      ...details,
      responsible: details.responsible.map((item, index) => {
        return Number(index) === Number(elementID)
          ? { ...item, [e.target.name]: e.target.value }
          : item;
      }),
    });
  };

  const removeUser = (index) => {
    setDetails({
      ...details,
      responsible: details.responsible
        .slice(0, index)
        .concat(details.responsible.slice(index + 1)),
    });
  };

  const addNewUser = () => {
    setDetails({
      ...details,
      responsible: [
        ...details.responsible,
        {
          name: "",
          vat: "",
          surname: "",
          email: "",
          phone: "",
        },
      ],
    });
  };

  return (
    <div ref={animationParent}>
      {list.map((item, index) => {
        const { name, surname, phone, vat, email } = item;
        return (
          <div key={index}>
            <Card margin={"0 0 2em 0"}>
              {list.length > 1 && (
                <Box className="removeWrap">
                  <Box
                    className="removeButton"
                    onClick={() => removeUser(index)}
                  >
                    <IconBox icon={<AiOutlineMinusCircle />} />
                  </Box>
                </Box>
              )}
              <Box width={"100%"}>
                <VStack>
                  <HStack width={"100%"} flexDir={["column", "row"]}>
                    <Box width={"100%"}>
                      <label>
                        Nome <span>*</span>
                      </label>
                      <Input
                        value={name}
                        data-elemid={index}
                        name={"name"}
                        onChange={handleChange}
                        placeholder="Nome"
                      />
                    </Box>
                    <Box width={"100%"}>
                      <label>
                        Sobrenome <span>*</span>
                      </label>
                      <Input
                        value={surname}
                        data-elemid={index}
                        name={"surname"}
                        onChange={handleChange}
                        placeholder="Sobrenome"
                      />
                    </Box>
                  </HStack>
                  <Box width={"100%"}>
                    <label>
                      NIF <span>*</span>
                    </label>
                    <Input
                      value={vat}
                      data-elemid={index}
                      name={"vat"}
                      onChange={handleChange}
                      placeholder="NIF"
                    />
                  </Box>
                  <HStack width={"100%"} flexDir={["column", "row"]}>
                    <Box width={["100%", "70%"]}>
                      <label>
                        Email <span>*</span>
                      </label>
                      <Input
                        value={email}
                        data-elemid={index}
                        name={"email"}
                        onChange={handleChange}
                        placeholder="Email"
                      />
                    </Box>
                    <Box width={["100%", "30%"]}>
                      <label>
                        Telefone <span>*</span>
                      </label>
                      <Input
                        value={phone}
                        data-elemid={index}
                        name={"phone"}
                        onChange={handleChange}
                        placeholder="911234567"
                      />
                    </Box>
                  </HStack>
                </VStack>
              </Box>
            </Card>
          </div>
        );
      })}
      <Card margin={"1em 0"} padding={"1em 2em"}>
        <HStack className={"addNewRes"} onClick={() => addNewUser()}>
          <Box>
            <Box className="title">Adicionar um responsável</Box>
            <Box className="desc">adicionar um novo responsável da horta</Box>
          </Box>
          <Box>
            <IconBox icon={<AiOutlinePlusCircle />} />
          </Box>
        </HStack>
      </Card>
    </div>
  );
};

export default PersonalDetails;
