import React from "react";
import { Box, HStack, useToast, Flex } from "@chakra-ui/react";
import {
  AiOutlineCloseCircle,
  AiOutlineCheckCircle,
  AiOutlineClockCircle,
} from "react-icons/ai";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import IconBox from "../../IconBox/IconBox";

const Thankyou = ({ setDetails, details }) => {
  const toast = useToast();
  const [animationParent] = useAutoAnimate();
  const { volunteer } = details;

  const handleChange = (val) => {
    setDetails({
      ...details,
      volunteer: val,
    });
  };
  return (
    <Box width={"100%"} ref={animationParent} className={"buttonVol"}>
      <Flex alignItems={""}>
        <Box>
          <Box
            lineHeight={1}
            fontSize={"1.8em"}
            fontWeight={"bold"}
            color={"#599331"}
          >
            Obrigado por responder!
          </Box>{" "}
          <Box fontSize={"1.2em"} lineHeight={1.1} mt={"1em"}>
            A sua escolha foi registada com sucesso. A sua resposta ajuda a
            equipa do projeto Hortas nas escolas a melhorar a sua intervenção. E
            organizar melhor as suas atividades
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default Thankyou;
